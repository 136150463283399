
































































































































import { Component, Vue } from 'vue-property-decorator'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller from '@/app/ui/controllers/PopUpBannerController'
import {
  EnumFilterByStatus,
  Utils
} from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import TrashIcon from '@/app/ui/assets/trash_icon.vue'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'

@Component({
  components: {
    EditIcon,
    Badge,
    Button,
    LoadingOverlay,
    Modal,
    TrashIcon,
  },
})
export default class PopUpBannerDetail extends Vue {
  controller = controller
  confirmationModal = false

  created(): void {
    controller.getPopUpBannerDetail(this.$route.params.id)
    EventBus.$on(EventBusConstants.INACTIVE_POPUP_BANNER_SUCCESS, () => {
      this.$router.push(`/banner/pop-up-banner`)
    })
  }

  inActiveBanner(): void {
    controller.inactivePopUpBanner(this.$route.params.id)
    this.confirmationModal = false
  }

  private toNormalize(text: string): string {
    const words = text.split('_')
    words.forEach((word, i) => {
      words[i] = word[0].toUpperCase() + word.substr(1)
    })

    return words.join(' ')
  }

  private formatDate(date: string) {
    return dayjs(date)
      .locale(dayjsID)
      .format('DD MMMM YYYY[\n]HH:mm [WIB]')
  }

  get statusBadgeType(): string {
    if (
      this.controller.popUpBannerDetail.status?.toUpperCase() ===
      EnumFilterByStatus.CANCELLED
    ) {
      return 'error'
    } else if (
      this.controller.popUpBannerDetail.status?.toUpperCase() ===
      EnumFilterByStatus.EXPIRED
    ) {
      return 'expire'
    } else if (
      this.controller.popUpBannerDetail.status?.toUpperCase() ===
      EnumFilterByStatus.ACTIVE
    ) {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get isActive(): boolean {
    return (
      controller.popUpBannerDetail.status?.toUpperCase() ===
        EnumFilterByStatus.ACTIVE ||
      controller.popUpBannerDetail.status?.toUpperCase() ===
        EnumFilterByStatus.PENDING
    )
  }

  private toCapitalize(string: string): string {
    return Utils.toCapitalize(string.replace('_', ''))
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.INACTIVE_POPUP_BANNER_SUCCESS)
  }
}
